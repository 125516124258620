import React, { useState } from "react"
import BannerHeader from "../components/bannerHeader"
import "../static/style/pageStyle/platform.scss"
import leftArrow from "../static/images/leftArrow.svg"

import { useStaticQuery } from "gatsby"
import { platformData } from "../services/helperFunctions.js"
import Parser from "html-react-parser"
import discCircle from "../static/images/disc-circle.svg"
import DownloadBtnBlack from "../components/DownloadBtn-black.js"
import { Helmet } from "react-helmet"
const Platform = props => {
  const data = useStaticQuery(
    graphql`
      {
        platform {
          platform {
            journey {
              attributes {
                cmpName
                heading
                subheading
                dynamicCmp {
                  featureHeading
                  cards {
                    cardHeading
                    content
                    iconUrl
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const [activeTab, setActiveTab] = useState(0)
  const [dActiveTab, setDActiveTab] = useState(true)
  
  const handleClick = header => {
    setActiveTab(header)
    setDActiveTab(!dActiveTab)
    if (activeTab === header) {
      setDActiveTab(!dActiveTab)
    }
    // console.log(activeTab, "as")
  }
  const [isOpen, setIsOpen] = useState({ 0: true });

  const toggleAccordion = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const { banner, section1 } = platformData(data)
  const title = "Platform | Pryzm - A Data Reliability & Observability Platform";
  return (
    <>
      <div className={`platform-v3 platform`}>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >        <title>{title}</title>
      </Helmet>
        <div className="page-content">
          <div className="page-wrapper">
            <div className="banner">
              <BannerHeader header={banner.heading} line={"two-line"}/>
            </div>
            <div className="sub-heading">
              <p>{Parser(banner.subheading)}</p>
            </div>
            {/* <div className="frame">
              <div className="main-frame">
              <div className=" main-container">
                <div className="feature-content">
                  {banner.dynamicCmp[activeTab].cards.map((content, index) => (
                    <PlatformFeature content={content} index={index} />
                  ))}
                </div>
                <div className="feature-heading">
                  <ul>
                    {banner.dynamicCmp.map((content, index) => (
                      <>
                        <li
                          className={`${activeTab === index ? "active" : ""}`}
                          onClick={() => handleClick(index)}
                          key={index}
                        >
                          <h2 className="list-heading">
                            {content.featureHeading}
                          </h2>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
              </div>
            </div> */}
            {/* <div className="frame-mobile">
              <div className="feature-heading-mobile">
                <ul>
                  {banner.dynamicCmp.map((content, index) => (
                    <>
                      <li onClick={() => toggleAccordion(index)} key={index}>
                        <h2
                          className={`${
                            isOpen[index]
                              ? "list-heading-active"
                              : "list-heading"
                          }`}
                        >
                          {content.featureHeading}
                        </h2>
                      </li>
                      <div
                        className="feature-content-mobile"
                      >
                        {isOpen[index] && (
                          <>
                            {banner.dynamicCmp[index].cards.map(
                              (content, index2) => (
                                <PlatformFeature
                                  content={content}
                                  index={index2}
                                />
                              )
                            )}
                            <p className="mobile-close-button"onClick={() => toggleAccordion(index)}></p>
                          </>
                        )}
                      </div>
                    </>
                  ))}
                </ul>
              </div>
            </div> */}
            <div className="steps">
                    {"Meta Data Collection,Behaviour Profiling,Anomaly Detection,Root Cause Analysis,Enhanced Visibility"
                      .split(",")
                      .map((content, index) => (
                        <>
                          <div className="steps-inner">
                            <h4>STEP {index + 1}</h4>
                            <div className="disc-circle">
                              <img src={discCircle} alt="" />
                            </div>
                            <div className="para">
                              <h2>{content}</h2>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
            {/* <div className="link-para">
              <div className="arrow">
                <img src={leftArrow} />
              </div>
              <div className="link-paragraph">
                <p>{section1.heading}</p>
              </div>
            </div> */}
            <div className="btn-area">
              <DownloadBtnBlack
                text="Download Pryzm Data Sheet"
                pdf={"PRYZM_Datasheet.pdf"}
              />
            </div>
            <div className="quote-para">
              <h2>
                <span>“ </span>
                {section1.subheading}
                <span> ”</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Platform
